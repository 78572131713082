@import 'locomotive.scss';
@import 'animation.scss';

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
}

body {
  line-height: inherit;
  margin: 0;
}

main {
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
  font-family: SF Pro Display, sans-serif;
}

.paperBackground {
  background-image: url('/background.webp');
  background-size: 100% auto;
}

#trailer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: #111111;
  border-radius: 20px;
  position: fixed;
  left: 0px;
  top: 0px;
  perspective: 1px;
  z-index: 10000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms ease;
}
#__next:hover > #trailer {
  opacity: 1;
}

#trailer-icon {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: opacity 400ms ease;
}

#trailer:not([data-type='']) > #trailer-icon {
  opacity: 1;
}

.interactable {
  cursor: none;
}
// The following code is to remove up and down arrow from input type number

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#nprogress .bar {
  background: #1ac74a !important;
}
