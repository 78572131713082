.appear-on-scroll-base {
  opacity: 0;
  transition: opacity 800ms;
  transition-delay: 200ms;
}
.appear-on-scroll {
  opacity: 1;
}
.slide-up-on-scroll-base {
  opacity: 0;
  transform: translateY(100%);
  transition: all 400ms cubic-bezier(0.24, 0.4, 0.48, 0.99);
}
.slide-up-on-scroll {
  transform: translateY(0%);
  opacity: 1;
}
.slide-up-by-line-base {
  opacity: 0;
  transform: translateY(100%);
  transition: all 400ms cubic-bezier(0.24, 0.4, 0.48, 0.99);
  // transition-delay: calc(200ms * attr(data-line-number, 0));
}
.slide-up-by-line {
  transform: translateY(0%);
  opacity: 1;
}
#scroll-container * {
  perspective: 1px;
  // Needed for Animation Locomotive dissapearing divs
}
